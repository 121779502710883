import ImageGallery from 'react-image-gallery';
import React from "react";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';

class Gallery extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
       images: []
    }
}

  componentDidMount() {
    var images = [];
    fetch('Gallery/GetImages')
        .then(response => response.json())
        .then(
          (result) => {
              result.map(data => {
                var image = {original: data.Directory +"/"+ data.FileNameMain,  thumbnail: data.Directory +"/"+ data.FileNameThumb}
                images.push(image);
              });
              this.setState({images: images});
          },
          (error) => {
            
          }
        );
        

}

  render() {
    const {images} = this.state;
    return <ImageGallery items={images} />;
  }
}

export default Gallery;