import React from "react";

class Services extends React.Component {
  render() {
    return (
<div className="col-container">
  <div className="col-item col-2">
  <h4>Čištění interiéru bez tepování</h4>
  <ul>
    <li>- komplet celé auto bez tepování</li>
    <li>- dáreček</li>
    <li style={{ color: '#e46661' }}>- stropnice nečistíme</li>
  </ul>

  <h4>Dezinfekce interiéru vozidla Ozonem</h4>
  <ul>
    <li>-nejúčinnější metoda pro vyčištění vzduchu, ploch a povrchů uvnitř uzavřených prostor</li>
  </ul>

  <h4>Čištění interiéru s tepováním</h4>
  <ul>
    <li>- komplet celé auto s tepováním sedadel </li>
    <li>- nejnovější technologie</li>
    <li>- dáreček</li>
    <li style={{ color: '#e46661' }}>- stropnice nečistíme</li>
  </ul>
    *Po tepování je potřeba nechat auto u nás aby vyschlo. Doba schnutí záleží na počasí.
    Po tuto dobu bude v bezpečí a pod kamerovým systémem.

  </div>
  <div className="col-item col-2">
  

  <h4>Obšití volantu</h4>
  <ul>
    <li>- použití jehly a nitě</li>
    <li>- polokožený, kožený potah </li>
  </ul>

  <h4>Leštění a seřízení světlometů</h4>
  <ul>
    <li>- kvalitní leštění</li>
    <li>- odzkoušené metody a postupy</li>
  </ul>

  <h4>Úklid bytů, kanceláří a firem, umývání oken </h4>

  <h4>Tepování sedaček, koberců v domácnosti, firmě</h4>

  <h4>Úklid kabiny kamionu (u zákazníka)</h4>
  </div>
</div>
  );
  }
}

export default Services;