import { ChangeEvent, useState } from 'react';

function FileUploadMultiple(props) {
    const [fileList, setFileList] = useState(null);
    
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFileList(e.target.files);
    };
  
    const handleUploadClick = () => {
      if (!fileList) {
        return;
      }
  
      const data = new FormData();
      files.forEach((file, i) => {
        data.append(`files`, file, file.name);
      });
        fetch('Gallery/InsertImages', {
        method: 'POST',
        body: data,
      })
            .then((response) => {
                if (!response.ok) {
                    alert(response.statusText);
                }
                else {
                    props.callBack(true);
                }
            });
        props.callBack(false);
    };
  
    const files = fileList ? [...fileList] : [];

    return (
      <div>
            <input type="file" onChange={handleFileChange} multiple />
        <ul>
          {files.map((file, i) => (
            <li key={i}>
              {file.name} - {file.type}
            </li>
          ))}
        </ul>
  
        <button onClick={handleUploadClick}>Uložit</button>
      </div>
    );
  }
export default FileUploadMultiple;
