import React from "react";
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Box from '@mui/material/Box';
import Button from "@material-ui/core/Button";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';


class OrderForm extends React.Component {

  handleChange()
  {
    console.log("0");
  };

  handleSubmit(event){   
    event.preventDefault();
    let formData = new FormData();
    console.log(event.target.CarType);
      formData.append('Name', event.target.Name.value);
      formData.append('Surname', event.target.Surname.value);
      formData.append('Contact', event.target.Contact.value);
      formData.append('Service', event.target.ServiceType.value);
      formData.append('DateAndTime', event.target.DateAndTime.value);
      formData.append('CarType', event.target.CarType.value);

      fetch('/Email/SendEmail', {
         method: 'post',
         body: formData
      })
      .then((response) => {
         if(!response.ok)
         {
            alert(response.statusText);
         }
         else
         {
            alert("Poptávka byla úspěšně odeslána.");
         }
         //window.location.reload(false);
      });
  }

  render() {
    const {handleSubmit}  = this;
    return (
<Box
               component="form"
               noValidate
               autoComplete="off"
               onSubmit={handleSubmit}
               >
<div className="col-container">

   <div className="col-item col-2">
      <p>
         <TextField label="Jméno" variant="standard" name="Name" size="small"/>
      </p>
      <p>
         <TextField label="Příjmení" variant="standard" name="Surname" size="small"/>
      </p>
      <p>
         <TextField label="Kontakt" variant="standard" name="Contact" size="small"/>
      </p>
   </div>
   <div className="col-item col-2">
      <p>
         <TextField label="Služba" variant="standard" name="ServiceType" size="small"/>
      </p>
      <p>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
               <DateTimePicker
               label="Datum a čas"
               InputProps={{
               disableUnderline: true
               }}
               ampm={false}
               onChange={this.handleChange}
               renderInput={(params) => 
               <TextField size="small" name = "DateAndTime" variant="standard"{...params} />
               }
               />
            </Stack>
         </LocalizationProvider>
      </p>
      <p>
         <TextField
            select
            defaultValue={0}
            label="Velikost auta"
            name="CarType"
            variant="standard"
            size="small"
            >
            <MenuItem value={0}>Osobní</MenuItem>
            <MenuItem value={1}>SUV</MenuItem>
            <MenuItem value={2}>Dodávka</MenuItem>
         </TextField>
      </p>
   </div>
   <Button
            color="primary" type="submit" variant="outlined">
           Odeslat poptávku
         </Button>

</div>
</Box>

  );
  }
}

export default OrderForm;