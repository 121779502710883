import React from "react";
import Login from "./login"
import ManageGallery from "./manageGallery"


class Admin extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
        isLogged: false,
      images: []
    }
}

  loadImages = () => {
    var tmp_images = [];
    fetch('Gallery/GetImages')
        .then(response => response.json())
        .then(
          (result) => {
              result.map(data => {
                var image = {name: data.Name,  thumbnail: data.Directory +"/"+ data.FileNameThumb}
                const count = tmp_images.push(image);
              });
              this.setState({images: tmp_images});
          },
          (error) => {
            
          }
        );
}

    handleLogged = (val) => {
 console.log("handleLogged:");
        console.log(val);
        if (val)
        {
            this.loadImages();
        }
  this.setState({isLogged: val})
}

  render() {
      const { images } = this.state;
      /*
      console.log("images:");
      console.log(images);
    {images.map(function(productSpec, i){
      return <span key={i}><b>Category Name:</b> {productSpec.name}</span>;
      */


      return (this.state.isLogged ? <ManageGallery /> : <Login isLogged={this.handleLogged} />);
/*
    return (
      <div>
      <div className="admin-images-buttons">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#626262"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
        </span>
        <span>
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#626262"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
        </span>
      </div>
        <div className="admin-images-container">
        {images.map(item => ( 
          
            <div className="admin-image" key={item.name}>
              <p>
                <img src={item.thumbnail}></img>
                <input type="checkbox" name={item.name}></input>
                </p>
            </div>
        ))}
        </div>
        </div>
    );
    */
  }
}

export default Admin;