import React from "react";
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Box from '@mui/material/Box';
import Button from "@material-ui/core/Button";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';


class Login extends React.Component {


  handleChange()
  {
    console.log("0");
  };

    handleSubmit = (event) => { 
    event.preventDefault();
    let formData = new FormData();
      formData.append('Username', event.target.username.value);
      formData.append('Password', event.target.password.value);


        fetch('/Account/Login', {
            method: 'post',
            body: formData
        })
            .then(response => response.json())
            .then(
                (result) => {
                    console.log("result login");
                    console.log(result);
                    this.props.isLogged(result);

          },
          (error) => {
            
          }
        );
  }

  render() {
    const {handleSubmit}  = this;
    return (

    <Box
    component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
    >
    <div className="col-container">
        <div className="col-item col-2">
            <p>
                <TextField label="Jméno" variant="standard" name="username" size="small"/>
            </p>
        </div>
        <div className="col-item col-2">
            <p>
                <TextField label="Heslo" variant="standard" type="password" name="password" size="small"/>
            </p>
        </div>
        <Button
            color="primary" type="submit" variant="outlined">
        Přihlásit
        </Button>
    </div>
    </Box>
  );
  }
}

export default Login;