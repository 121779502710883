import ReactDOM from "react-dom";
import React from "react";
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Home from "./pages/about-us";
import Services from "./pages/services";
import PriceList from "./pages/price-list"
import OrderForm from "./pages/order-form"
import Gallery from "./pages/gallery"
import Contact from "./pages/contact";
import Admin from "./pages/admin";
import Switch from "react-switch";
import { Outlet, Link } from "react-router-dom";
import SlideToggle from "react-slide-toggle";
import Logo from "./images/logo.png"



class App extends React.Component{
   constructor(props) {
      super(props);
      this.state = { 
         toggleEvent: 0,
         currentCategory: ""
      }
}

categorySwitch(categoryName) {
   console.log("CategorySwitch:" + categoryName);
   switch(categoryName) {
     case 'about-us':
       return <Home/>;
       case 'services':
       return <Services/>;
       case 'pricelist':
       return <PriceList/>;
       case 'order-form':
       return <OrderForm/>;
       case 'gallery':
       return <Gallery/>;
       case 'contact':
       return <Contact/>;
       case 'admin':
         return <Admin/>;
     default:
      return <Home/>;
   }
 }

 setCategory(categoryName)
 {
   console.log("SetCategory:" + categoryName);
   this.setState({currentCategory: categoryName});    
 }

    getCategoryCZName()
    {
        switch (this.state.currentCategory) {
            case 'about-us':
                return 'O nás';
            case 'services':
                return 'Služby';
            case 'pricelist':
                return 'Ceník';
            case 'order-form':
                return 'Objednávkový formulář';
            case 'gallery':
                return 'Galerie';
            case 'contact':
                return 'Kontakt';
            case 'admin':
                return 'Administrace';
            default:
                return 'O nás';
        }
    }

onToggle() {
    //this.isToggled();
   console.log("onToogle");
   this.setState({ toggleEvent: Date.now() });
    };

    isCollapsed = () =>
    {
        const element = document.getElementById("mobile-collapsible");
        if (element == null)
        {
            return true;
        }
        var display = element.style.display;
        console.log(display);
        if (display.trim() == "")
        {
            console.log("vracím true");
            return true;
        }
        return false;
        //console.log(display);
    }

render(){
    const { toggleEvent, currentCategory } = this.state;
  return (
    <div id="page-wrap">
    <div id="page-header">
       <div className="entry-header" data-parallax="" data-image="https://wp-royal.com/themes/bard-free/demo/wp-content/uploads/sites/2/2021/02/bard_header_1.jpg">
          <div className="cv-outer">
             <div className="cv-inner">
                <div className="header-logo">
                   <a href="#" title="Svezikara" className="logo-img">
                   <img src={Logo} alt="Svezikara"/>
                   </a>
                   <br/>
                   <p className="site-description">Najdete nás</p>
                </div>
                <div className="header-socials">
                    <a href="https://www.facebook.com/svezikara" target="_blank">
                      <span className="header-socials-icon">
                         <svg className="svg-inline--fa fa-facebook-f fa-w-9" aria-hidden="true" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"></path>
                         </svg>

                      </span>
                   </a>
                    <a href="https://www.instagram.com/svezikara/" target="_blank">
                      <span className="header-socials-icon">
                         <svg className="svg-inline--fa fa-instagram fa-w-14" aria-hidden="true" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                         </svg>

                      </span>
                   </a>
                </div>
             </div>
          </div>
       </div>
       <div id="main-nav" className="clear-fix">
          <div className="boxed-wrapper">
             <div className="main-nav-buttons">

                <div className="main-nav-sidebar">
                   <span className="btn-tooltip">Menu</span>
                   <div onClick={() => this.onToggle()}>
                      <span></span>
                      <span></span>
                      <span></span>
                   </div>
                </div>
             </div>

             <div className="main-nav-icons">
                      </div>

                      {this.isCollapsed() ? <div className="pageNameMobile">{this.getCategoryCZName()}</div> : null}

             <nav className="main-menu-container">

                <ul id="main-menu" className="">
                   <li onClick={() => this.setCategory("about-us")} id="menu-item-17" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17">
                      <a href="#">O nás</a>
                   </li>
                   <li onClick={() => this.setCategory("services")} id="menu-item-17" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17">
                   <a href="#">Služby</a>
                   </li>
                   <li onClick={() => this.setCategory("pricelist")} id="menu-item-17" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17">
                   <a href="#">Ceník</a>
                   </li>
                   <li onClick={() => this.setCategory("order-form")} id="menu-item-17" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17">
                     <a href="#">Objednávkový formulář</a>
                   </li>
                   <li onClick={() => this.setCategory("gallery")} id="menu-item-17" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17">
                   <a href="#">Galerie</a>
                   </li>
                   <li onClick={() => this.setCategory("contact")} id="menu-item-17" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17">
                     <a href="#">Kontakt</a>
                   </li>
                </ul>
             </nav>
             <SlideToggle collapsed toggleEvent={toggleEvent}>
          {({ setCollapsibleElement }) => (
            <div className="my-collapsible">
              <nav 
                id="mobile-collapsible"
                className="my-collapsible__content"
                ref={setCollapsibleElement}
              >
                <ul id="mobile-menu" className="">
                   <li onClick={() => {this.setCategory("about-us"); this.onToggle();}}className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15">
                   <a href="#">O nás</a>
                   </li>
                   <li onClick={() => {this.setCategory("services"); this.onToggle();}}className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15">
                   <a href="#">Služby</a>
                   </li>
                   <li onClick={() => {this.setCategory("pricelist"); this.onToggle();}}className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15">
                   <a href="#">Ceník</a>
                   </li>
                   <li onClick={() => {this.setCategory("order-form"); this.onToggle();}}className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15">
                     <a href="#">Objednávkový formulář</a>
                   </li>
                   <li onClick={() => {this.setCategory("gallery"); this.onToggle();}} className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15">
                     <a href="#">Galerie</a>
                   </li>
                   <li onClick={() => {this.setCategory("contact"); this.onToggle();}} className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15">
                     <a href="#">Kontakt</a>
                   </li>
                </ul>
             </nav>
            </div>
          )}
        </SlideToggle>
          </div>
       </div>

    </div>

    <div className="page-content">
       <div style={{height: "25px"}}></div>
      <div className="main-content clear-fix boxed-wrapper" data-sidebar-sticky="1">
	      <div className="main-container">
            <BrowserRouter>
               <Routes>
                <Route path="*" element={<Navigate to="/" replace />}/>
                <Route path="/" element={this.categorySwitch(currentCategory)}/>
                <Route path="admin" element={<Admin />}/>
               </Routes>
            </BrowserRouter>
         </div>
      </div>
      <div style={{height: "25px"}}></div>
    </div>

    <footer id="page-footer" className="clear-fix">
    <iframe style={{border:'none'}} src="https://frame.mapy.cz/s/basezologo" width="100%" height="600" frameBorder="0"></iframe>
    </footer>
    </div>
  );
}
}
export default App;