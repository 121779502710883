import React from "react";

class Contact extends React.Component {
  render() {
    return (
<div className="col-container">
<div className="col-item col-2">
<p>VERONIKA PARTYKOVÁ<br/>
  IČO: 17511151
</p>
  <h4>Otevírací doba:</h4>
<p>Po - Pá: na objednání<br />
    So: po předchozí domluvě<br />
    Ne: zavřeno<br />
</p>
  </div>
  <div className="col-item col-2">
  <h4>Kontaktní údaje:</h4>
    <p>+420 605 121 665<br/>
    <a href="mailto:info@svezikara.cz">info@svezikara.cz</a></p>
    <p>Křižanovice 130, 685 01<br/>49°8.682129'N,16°56.319033'E</p>
  </div>
</div>
  );
  }
}

export default Contact;