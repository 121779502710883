import React from "react";

class AboutUs extends React.Component {
  render() {
    return (
<div className="col-container">
<p>Jmenuji se Veronika.</p>
<p>Již několik let se věnuji čištění interiérů vozů.</p>
<p>Nejdříve to pro mne byl koníček, který přerostl v profesionální činnost.</p>
<p>Postupem času jsem služby rozšířila také o obšívání potahů, které pro mne bylo opravdu výzvou.</p>
<p>Nyní se mohu pochlubit spousty spokojenými zákazníky a prací, která mne baví a naplňuje.</p>
<p>Ke své práci používám moderní a profesionální vybavení.</p>
<p>Čištění provádím v prostorách, které byly právě pro tento účel vybudovány.</p>
<p>Budu se těšit na Vaši návštěvu .</p>
</div>
  );
  }
}

export default AboutUs;