import ImageGallery from 'react-image-gallery';
import React from "react";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import Checkbox from '@mui/material/Checkbox';
import FileUploadMultiple from "./fileUploadMultiple"

class ManageGallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            images: [],
            selectImages: []
        }
    }

    componentDidMount() {
            this.getImages();
    }

    getImages()
    {
        var images = [];
        fetch('Gallery/GetImages')
            .then(response => response.json())
            .then(
                (result) => {
                    result.map(data => {
                        var image = { original: data.Directory + "/" + data.FileNameMain, thumbnail: data.Directory + "/" + data.FileNameThumb, name: data.Name }
                        images.push(image);
                    });
                    this.setState({ images: images });
                },
                (error) => {
                    alert(error);
                }
        );
        this.state.callGetImages = false;
    }

    handleRemoveImages()
    {
        let params = new URLSearchParams();
        {
            this.state.selectImages.map((item, i) => (
                params.append('names['+i+']', item)
            ))
        }


        fetch('Gallery/DeleteImages', {
            method: 'post',
            body: params
        })
            .then((response) => {
                if (!response.ok) {
                    alert(response.statusText);
                }
                else
                {
                    this.getImages();
                }
            });

    }

    handleCheck = (val) => 
    {
        var result = this.state.selectImages.some(item => val === item);
        return result;
    }

    handleChecked = (val) => (event) => 
    {
        this.state.selectImages.push(val);
        this.forceUpdate();
    }

    handleUploaded = (val) => {
        if (val) {
            this.getImages();
        }
    }

    render() { 
        const { images } = this.state;
      

        return (
            <div>
                <FileUploadMultiple callBack={this.handleUploaded} />
            <div className="admin-images-buttons">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#626262" onClick={() => this.handleRemoveImages()}><path d="M0 0h24v24H0z" fill="none" /><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" /></svg>
                </span>
            </div>
            <div className="admin-images-container">
                {images.map((item, i)  => (

                    <div className="admin-image" key={item.original}>
                        <p>
                            <img src={item.thumbnail}></img>
                           
                            <Checkbox name={"img_" + i} checked={this.handleCheck(item.name)} value={item.name} onClick={this.handleChecked(item.name)}/>
                        </p>
                    </div>
                ))}
            </div>
        </div>
            
        )
    }
}

export default ManageGallery;