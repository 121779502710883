import React from "react";

class PriceList extends React.Component {
  render() {
    return (
        <div className="col-container">
<h4>PLATBA HOTOVĚ, FAKTURAČNĚ</h4>
<h4>Auto prosím dovezte vyklizené včetně zavazadlového prostoru. Za vyklizení a vrácení věcí na své místo k ceně 250,-</h4>
  <div className="col-item col-2">

  <h4>Čištění interiéru bez tepování 1200 kč (SUV + 400kč, VÍCE MÍSTNÉ DODÁVKY + 800kč)</h4>
  <ul>
    <li>- vysátí celého auta včetně zavazadlového prostoru</li>
    <li>- utření prachu</li>
    <li>- ošetření plastových částí včetně palubní desky</li>
    <li>- vyleštění vnitřních oken </li>
    <li style={{ color: '#e46661' }}>- stropnice nečistíme</li>
  </ul>
  <br/>
  <h4>Čištění interiéru s tepováním 1500 kč (SUV + 400kč) mimo strop</h4>
  <ul>
    <li>- vysátí celého auta včetně zavazadlového prostoru</li>
    <li>- utření prachu</li>
    <li>- ošetření plastových částí včetně palubní desky</li>
    <li>- vyleštění vnitřních oken</li>
    <li>- umytí gumových koberečků</li>
    <li>- tepování sedadel </li>
    <li style={{ color: '#e46661' }}>- stropnice nečistíme</li>
  </ul>
  </div>
  <div className="col-item col-2">
  <h4>Ozonové čištění interiéru vozu 400 kč/ cca 1hod </h4>
  <h4>Obšití potahu volantu 600 Kč + zvolený materiál  </h4>
  <h4>Leštění světlometů za 1pár 1500kč</h4>
  <h4>Ošetření, impregnace kůže 700kč</h4>

  <h4>Úklid bytů, kanceláří a firem, umývání oken (ceník na vyžádání)</h4>
  <h4>Tepování sedaček, koberců v domácnosti, firmě (ceník na vyžádání)</h4>
  <h4>Úklid kabiny kamionu (u zákazníka) 1500 kč + doprava</h4>
  </div>
  <br/>
  <h5>Všechny ceny jsou uvedeny včetně DPH. Vždy záleží na míře znečištění vozidla a použitých materiálů v interiéru.</h5>
</div>
  );
  }
}

export default PriceList;